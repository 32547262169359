(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MonitoringRuleDialogController', MonitoringRuleDialogController);

    MonitoringRuleDialogController.$inject = ['$scope', '$stateParams', '$uibModalInstance', 'entity', 'MonitoringRule'];

    function MonitoringRuleDialogController ($scope, $stateParams, $uibModalInstance, entity, MonitoringRule) {
        var vm = this;
        vm.monitoringRule = entity;

        
//        
//        vm.load = function(id) {
//            MonitoringRule.get({id : id}, function(result) {
//                vm.monitoringRule = result;
//                vm.monitoringRule.typeRule.name =  vm.monitoringRule.typeRule;
//            });
//        };
        
        MonitoringRule.queryTypeRules({}, function(result) {
        	vm.listTypeRules =  result;
        	
            // Extraire les groupes uniques
            var uniqueGroups = {};
        	vm.groups = [];
        	vm.listTypeRules.forEach(function (rule) {
        	    if (!uniqueGroups[rule.group]) {
        	        uniqueGroups[rule.group] = true;
        	        vm.groups.push(rule.group);
        	    }
        	});
            
            
         	if(vm.monitoringRule && vm.monitoringRule.$promise){
        		vm.monitoringRule.$promise.then(function(value) {
        			
        			function findTypeRuleFunction(item, index, array) {
        				if(item.name== vm.monitoringRule.typeRule){
       					 	vm.monitoringRule.typeRule = item;
        				}
        			}
        			
        			vm.listTypeRules.forEach(findTypeRuleFunction);
    
    	    	});	
            }
            // Initialiser les règles filtrées
            vm.updateFilteredTypeRules();
        });
        
        
        var onSaveSuccess = function (result) {
            $scope.$emit('wamasysApp:monitoringRuleUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        };

        var onSaveError = function () {
            vm.isSaving = false;
        };

        vm.save = function () {
            vm.isSaving = true;
            vm.monitoringRule.typeRule =  vm.monitoringRule.typeRule.name;
            if (vm.monitoringRule.id !== null) {
                MonitoringRule.update(vm.monitoringRule, onSaveSuccess, onSaveError);
            } else {
                MonitoringRule.save(vm.monitoringRule, onSaveSuccess, onSaveError);
            }
        };

        vm.clear = function() {
            $uibModalInstance.dismiss('cancel');
        };
        
        


        // Valeur par défaut du groupe sélectionné (affiche tout au départ)
        vm.selectedGroup = '';

        // Fonction pour filtrer la liste selon le groupe sélectionné
        vm.getFilteredTypeRules = function () {
            if (!vm.selectedGroup) {
                // Si aucun groupe n'est sélectionné, afficher toute la liste
                return vm.listTypeRules;
            }
            
            // Filtrer les règles selon le groupe sélectionné
            return vm.listTypeRules.filter(function (rule) {
                return rule.group === vm.selectedGroup;
            });
        };

        // Mettre à jour les règles filtrées
        vm.updateFilteredTypeRules = function () {
            vm.filteredTypeRules = vm.getFilteredTypeRules();
        };



    }
})();
