(function() {
    'use strict';

    angular
        .module('wamasysApp')
        .controller('MonitoringRuleController', MonitoringRuleController);

    MonitoringRuleController.$inject = ['$scope', '$state', 'MonitoringRule', 'ParseLinks', 'AlertService', 'pagingParams', 'paginationConstants','$filter'];

    function MonitoringRuleController ($scope, $state, MonitoringRule, ParseLinks, AlertService, pagingParams, paginationConstants,$filter) {
        var vm = this;
        vm.loadAll = loadAll;
        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.loadAll();

        
		var $dialog = $(
				'<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-hidden="true" style="padding-top:1%; overflow-y:visible;">' +
				'<div class="modal-dialog modal-m">' +
				'<div class="modal-content">' +
					'<div class="modal-header">'+
					'<h3 class="modal-title" >Run monitoring alert</h3>'+
					'<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'+
			      
					'</div>' +
					'<div class="modal-body">' +
						'<div class="progress progress-striped active" style="margin-bottom:0;"><div class="progress-bar" style="width: 100%"></div></div>' +
					'</div>' +
				'</div></div></div>');
    	
		var options = {};
		var message = 'Please wait ! Action in progress.';
		var settings = $.extend({
			dialogSize: 'lg',
			progressType: '',
			onHide: null // This callback runs after the dialog was hidden
		}, options);
		$dialog.find('.modal-dialog').attr('class', 'modal-dialog').addClass('modal-' + settings.dialogSize);
		$dialog.find('.progress-bar').attr('class', 'progress-bar');


		vm.fermerDialog = function(){
			  $dialog.modal('hide');
		}
        
        
        
        

        vm.runRule = function (idRuleParam){
      
			$dialog.find('.modal-body').html( "");
    		$dialog.modal();
        	MonitoringRule.runMonitoringRule({idRule:idRuleParam}, onSuccessrunRules, onErrorrunRules);
        	function onSuccessrunRules(data, headers) {
        	      var popupContent = '';

        	        for (var manager in data) {
        	        	if(manager.startsWith("$") || manager.startsWith("toJSON")){
        	        		continue;
        	        	}
        	            popupContent += '<h4 style="text-align: center; "> ' + $filter('translate')('wamasysApp.monitoringRule.recipientEnum.'+manager)   + '</h4>';
        	            var managerData = data[manager];

        	            if(!managerData){
        	            	continue;
        	            }
        	            for (var key in managerData) {
        	         
        	                popupContent += '<h5>' +$filter('translate')('wamasysApp.monitoringRule.typeEnum.'+key)  + '</h5>';
        	                popupContent += '<table class="table table-bordered">';
        	                popupContent += '<thead><tr>';

            	            if(!managerData[key] || ! managerData[key][0]){
            	            	continue;
            	            }
        	                // Construire les en-têtes dynamiquement
        	                var firstItem = managerData[key][0];

        	                for (var header in firstItem) {
        	                	if(header==="areaId"){
        	                		continue;
        	                	}
        	                    popupContent += '<th>' +  $filter('translate')('wamasysApp.monitoringRule.header.'+header)   + '</th>';
        	                }
        	                popupContent += '</tr></thead><tbody>';


        	                // Construire les lignes dynamiquement
        	                Array.prototype.forEach.call( managerData[key], function(item){
        	             
        	                    popupContent += '<tr>';
        	                    for (var value in item) {
        	                       	if(value==='areaId'){
            	                		continue;
            	                	}

        	                    	var valCol = item[value];
        	                       	if(value==='monitoringRule'){
        	                       		valCol = 	$filter('translate')('wamasysApp.monitoringRule.typeEnum.'+valCol.typeRule) .replace('X', valCol.param1).replace('Y', valCol.nbDays)
        	                       		valCol = valCol;
        	                       	}else if(value==='message'){
        	                    		valCol = valCol.replaceAll(';','; ') 
        	                    	}

        	                        popupContent += '<td>' +valCol + '</td>';
        	                    }
        	                    popupContent += '</tr>';
        	                	
        	                }
        	                		
        	                );
        	                
        	                
//        	                managerData[key].forEach(function(item) {
//        	                    popupContent += '<tr>';
//        	                    for (var value in item) {
//        	                        popupContent += '<td>' + item[value] + '</td>';
//        	                    }
//        	                    popupContent += '</tr>';
//        	                });

        	                popupContent += '</tbody></table>';
        	            }
    	                popupContent += '<hr/>';
        	        }

        			$dialog.find('.modal-body').html( popupContent);
     
        	        
            }
            function onErrorrunRules(error) {
                AlertService.error(error.data.message);
            }
            
        }

        
        vm.runRules = function (){
      
			$dialog.find('.modal-body').html( "");
    		$dialog.modal();
        	MonitoringRule.runMonitoringRules({}, onSuccessrunRules, onErrorrunRules);
        	function onSuccessrunRules(data, headers) {
        	      var popupContent = '';

//        	        // Transformer les données en tableau plat
//        	        vm.flatData = [];
//        	        angular.forEach(data, function (group) {
//        	            angular.forEach(group, function (items) {
//        	                vm.flatData = vm.flatData.concat(items);
//        	            });
//        	        });
//
//        	        vm.flatData.sort(function (a, b) {
//        	        	if(!a || !b) return 0;
//        	            if (a.area < b.area) return -1;
//        	            if (a.area > b.area) return 1;
//        	            if (a.libelle > b.libelle) return -1; // Tri secondaire
//        	            if (a.libelle < b.libelle) return 1;
//        	            return 0;
//        	        });
//        	      
//
//	                popupContent += '<table class="table table-bordered">';
//	                popupContent += '<thead><tr>';
//	               popupContent += '<th style="  width: 20%;">Area</th>'+'<th style="  width: 20%;">Règle</th>'+'<th style="  width: 20%;">Erreur</th>'+'<th style="  width: 40%;">Détail</th>';
//	              
//	                popupContent += '</tr></thead><tbody>';
//	                
//	                // Construire les lignes dynamiquement
//	                Array.prototype.forEach.call(vm.flatData, function(item){
//	                    popupContent += '<tr>';
//	                    for (var value in item) {
//	                    	var valCol = item[value];
//	                    	if(value==='message'){
//	                    		if(valCol && valCol.length>200){
//	                    			valCol = valCol.substring(0,200)+'...';
//	                    		}
//	                    		valCol = valCol.replaceAll(';','; ') ;
//	                    	}
//	                        popupContent += '<td>' +valCol + '</td>';
//	                    }
//	                    popupContent += '</tr>';
//	                	
//	                });
//	                
//	                popupContent += '</tbody></table>';
	                
        	        for (var manager in data) {
        	        	if(manager.startsWith("$") || manager.startsWith("toJSON")){
        	        		continue;
        	        	}
        	            popupContent += '<h4 style="text-align: center; ">' + $filter('translate')('wamasysApp.monitoringRule.recipientEnum.'+manager)    + '</h4>';
        	            var managerData = data[manager];

        	            if(!managerData){
        	            	continue;
        	            }
        	            
        	            for (var key in managerData) {
        	                popupContent += '<h5>' + $filter('translate')('wamasysApp.monitoringRule.typeEnum.'+key)  + '</h5>';
        	                popupContent += '<table class="table table-bordered">';
        	                popupContent += '<thead><tr>';

            	            if(!managerData[key] || ! managerData[key][0]){
            	            	continue;
            	            }
        	                // Construire les en-têtes dynamiquement
        	                var firstItem = managerData[key][0];

        	                for (var header in firstItem) {
        	                    popupContent += '<th>' + $filter('translate')('wamasysApp.monitoringRule.header.'+header)   + '</th>';
        	                }
        	                popupContent += '</tr></thead><tbody>';


        	                // Construire les lignes dynamiquement
        	                Array.prototype.forEach.call( managerData[key], function(item){
        	                    popupContent += '<tr>';
        	                    for (var value in item) {
        	                    	var valCol = item[value];
        	                    	if(value==='message'){
        	                    		if(valCol && valCol.length>200){
	    	                    			valCol = valCol.substring(0,200)+'...';
	    	                    		}
	    	                    		valCol = valCol.replaceAll(';','; ') ;
        	                    	}
        	                        popupContent += '<td>' +valCol + '</td>';
        	                    }
        	                    popupContent += '</tr>';
        	                	
        	                }
        	                		
        	                );
        	                
        	                
//        	                managerData[key].forEach(function(item) {
//        	                    popupContent += '<tr>';
//        	                    for (var value in item) {
//        	                        popupContent += '<td>' + item[value] + '</td>';
//        	                    }
//        	                    popupContent += '</tr>';
//        	                });

        	                popupContent += '</tbody></table>';
        	            }
    	                popupContent += '<hr/>';
        	        }

        			$dialog.find('.modal-body').html( popupContent);
     
        	        
            }
            function onErrorrunRules(error) {
                AlertService.error(error.data.message);
            }
            
        }


        function loadAll () {
        	
            MonitoringRule.query({
                page: pagingParams.page - 1,
                size: paginationConstants.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.monitoringRules = data;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage (page) {
            vm.page = page;
            vm.transition();
        }

        function transition () {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch
            });
        }

    }
})();
